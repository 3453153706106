import React, { Component } from 'react';
import { Redirect,withRouter ,HashRouter, Route, Switch} from 'react-router-dom';
import Loadable from 'react-loadable';
import './App.scss';
import { BounceLoader } from 'halogenium';

const loading = () =>   <BounceLoader color='#00BFA5' size="250px" margin="20px" style={{marginLeft:"40%",marginTop:"15%"}}/> 
// Containers
const DefaultLayout = Loadable({
  loader: () => import('./containers/DefaultLayout'),
  loading
});

// Pages
const Login = Loadable({
  loader: () => import('./views/Pages/Login'),
  loading
});


class App extends Component {

  constructor(props) {
    super(props);

    let { history } = this.props;
  }

  render() {
    return (
      <HashRouter>
          <Switch>
            <Route exact path="/login" name="Login Page" component={Login} />
            <Route path="/" name="Home" component={DefaultLayout} />
          </Switch>
      </HashRouter>
    );
  }
}

export default App;
